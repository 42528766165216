<!--
* @Author: DY
* @Date: 2020-05-27 14:52:13
* @Description: 选择市场信息价格
-->
<template>
    <div class="showAggregatePeriod">
        <div class="table-bg" v-loading="loading">
            <div class="table-header">
                <div class="el_left">
                    <el-select
                        v-model="year"
                        placeholder="请选择年份"
                        @change="generalQuery"
                    >
                        <template v-for="(item, index) in yearData">
                            <el-option :key="index" :label="item.name" :value="item.value"></el-option>
                        </template>
                    </el-select>
                </div>
            </div>
            <el-table
                :data="tableData"
                style="width: 100%"
                height="calc(100% - 0.9rem)"
            >
                <el-table-column
                    label="序号"
                    type="index"
                    width="70"
                    :index="indexMethod"
                    v-if="true"
                >
                </el-table-column>
                <el-table-column
                    prop="region_name"
                    label="地区"
                >
                </el-table-column>
                <el-table-column
                    prop="current_period_total"
                    label="累计期次"
                >
                </el-table-column>
                <el-table-column
                    prop="including_tax_price"
                    label="C30泵送价格"
                >
                </el-table-column>
                <el-table-column
                    prop="current_period"
                    label="发布期次"
                >
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="showCurrentPeriod(scope.row)">
                            {{ scope.row.current_period }}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="created"
                    label="维护时间"
                >
                </el-table-column>
                <el-table-column
                    prop="maintainer"
                    label="维护人"
                >
                </el-table-column>
            </el-table>
            <div class="table-page">
                <div class="el_right">
                    <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :page-sizes="[20,40,60,80,100]"
                        :page-size="limit"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'show-aggregate-period',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            // 年份数据
            yearData: [],
            // 信息价累计期次列表数据
            tableData: [],
            // 查询条件
            keyword: '',
            // 区域编号
            regionalNumber: '',
            // 年份
            year: '',
            // 数据总条数
            total: 0,
            // 当前页码
            page: 1,
            // 每页显示条目个数
            limit: 20,
        };
    },
    watch: {},
    computed: {},
    created() {
        // 获取市场信息价格列表数据
        this.getYearData();
    },
    mounted() {},
    methods: {
        // 获取当前期次信息价年份集合
        getYearData() {
            this.loading = true;
            this.$axios
                .get('/interfaceApi/sale/info_price/get_year_by_regional_number?'
                    + `regionalNumber=${this.extr.regionalNumber}`)
                .then(res => {
                    this.$set(this, 'yearData', res);
                    if (res.length > 0) {
                        this.year = res[res.length - 1].value;
                        this.getTableData();
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取市场信息价格列表数据
        getTableData() {
            this.loading = true;
            this.$axios
                .get('/interfaceApi/sale/info_price/get_paging_period_list?'
                    + `keyword=${this.keyword}`
                    + `&regionalNumber=${this.extr.regionalNumber}`
                    + `&year=${this.year}`
                    + `&pageindex=${this.page}`
                    + `&pagesize=${this.limit}`)
                .then(res => {
                    this.$set(this, 'tableData', res.rows);
                    // 数据总条数
                    this.total = res.total;
                    // 强制刷新
                    this.$forceUpdate();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 设置每页条数
        handleSizeChange(val) {
            this.limit = val;
            this.getTableData();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.page = val;
            this.getTableData();
        },
        // 查询
        generalQuery() {
            this.getTableData();
        },
        // 计算列表序号
        indexMethod(index) {
            return (this.page - 1) * this.limit + index + 1;
        },
        // 关闭
        close() {
            this.$parent.hide();
        },
        showCurrentPeriod(row) {
            this.$toast({
                title: true,
                text: '信息价查看',
                type: 'eject',
                width: '15rem',
                height: '80%',
                t_url: 'salesBusiness/marketInforPrice/maintainPop',
                viewPosition: 'view',
                extr: {
                    table: this,
                    row: row,
                    isReadOnly: true,
                },
            });
        },
    },
};
</script>

<style lang="stylus">
.showAggregatePeriod
    padding 0.14rem
    height 100%
    .table-bg
        height 100%
        .table-header
            overflow hidden
            margin-bottom 0.14rem
            .el_left
                .el-button
                    height 0.32rem
                    padding 0 0.15rem
                    line-height 0.32rem
            .conditionQuery
                width 3.6rem
                .el-input-group__append
                    background #1577d2
                    border 1px solid #1577d2
                    .el-button
                        color #fff
        .el-table
            table
                border-collapse: collapse;
                th,td
                    padding 0.08rem 0
                    border 1px solid #DEDFE0
                &::before
                    background-color #d7d7d7
                thead
                    th
                        background #EDF0F5
                        color #022782
                        font-weight normal
                        font-size .16rem
            .el-table__body-wrapper
                overflow hidden
        .table-page
            overflow hidden
</style>
